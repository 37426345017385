
var helpers = {
	URL_Params_to_Obj() {
		var search = location.search.substring(1);
		var queryObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
		for (var prop in queryObj) {
			if (queryObj.hasOwnProperty(prop)) {
				queryObj[prop] = decodeURIComponent(queryObj[prop]);
			}
		}
		return queryObj;
	},
	formatNumber(value) {
		if (Intl && Intl.NumberFormat) {
	        return new Intl.NumberFormat().format(value);
	    }
	    else return value;
	},
	animateNumber(vueAppData, toValue, duration) {
		$({someValue: vueAppData.contentManagement.nrEditorsDevs}).animate({someValue: toValue}, {
	        duration: duration,
	        step: function(currentValue) {
	        	var currentValueRounded = Math.round(currentValue);
	        	if (vueAppData.contentManagement.nrEditorsDevs != currentValueRounded) {
	            	vueAppData.contentManagement.nrEditorsDevs = currentValueRounded;
	        	}
	        },
	        complete : function() {
	            vueAppData.contentManagement.nrEditorsDevs = toValue;
	        }
		});
	}
}

export default helpers;