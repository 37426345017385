// import Cookies from 'js-cookie';
import Helpers from './Helpers.js';
import Vue from 'vue';
import Loader from './components/Loader.vue';
import DonutChart from './components/DonutChart.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'

require('es6-promise').polyfill();

Vue.filter('formatNumber', Helpers.formatNumber);
Vue.filter('absToPercent', function (value) {
    return value*100;
});

$(document).ready(function() {

var lang = $('html').attr('lang') || 'en';

var sliderDefaults = {
    height: 12,
    tooltip: 'always',
    tooltipStyle: {
        "backgroundColor": "#05149b",
        "borderColor": "#05149b"
    },
    processStyle: {"backgroundColor": "#05149b"},
    railStyle: {"backgroundColor": "#bccff7"}
};
var TooltipFormatterNumber = function(val) { return Helpers.formatNumber(val); };
var TooltipFormatterNumberDollars = function(val) { return '$' + Helpers.formatNumber(val); };

var personalizedVideoTimeline;
var personalizedVideo;

function setupPersonalizedVideoAnimations() {
    var tl = new TimelineMax({
        repeat: 0,
        paused: true,
    });
    var slide_total = $('#slide-total').get(0);
    var slide_a = $('#slide-a').get(0);
    var slide_b = $('#slide-b').get(0);
    var slide_c = $('#slide-c').get(0);

    TweenMax.set(slide_total, {
        y: '100%'
    });
    TweenMax.set([slide_a, slide_b, slide_c], {
        x: '100%'
    });

    tl
        .to(slide_total, 0.5, {
            y: '0%',
            ease: Power2.easeOut
        }, '1.5')
        .to(slide_total, 0.15, {
            opacity: '0'
        }, '4.3')

        .to(slide_a, 0.5, {
            x: '0%',
            ease: Power2.easeOut
        }, '5.5')
        .to(slide_a, 0.15, {
            opacity: '0'
        }, '8')

        .to(slide_b, 0.5, {
            x: '0%',
            ease: Power2.easeOut
        }, '9.2')
        .to(slide_b, 0.15, {
            opacity: '0'
        }, '12.5')

        .to(slide_c, 0.5, {
            x: '0%',
            ease: Power2.easeOut,
        }, '13.5')
        .to(slide_c, 0.15, {
            opacity: '0'
        }, '16.2')

    personalizedVideoTimeline = tl;

    var tlStart = 60;
    var tlDuration = tl.duration();
    var tlEnd = tlStart + tlDuration;

    console.log('duration:', tlDuration);

    personalizedVideo.addEventListener('timeupdate', function() {
        // console.log(personalizedVideo.currentTime);
        if (personalizedVideo.currentTime > tlStart && personalizedVideo.currentTime <= tlEnd) {
            if (!personalizedVideoTimeline.isActive() && !personalizedVideo.paused) {
                // personalizedVideoTimeline.seek(personalizedVideo.currentTime - tlStart, true);
                console.log('play');
                personalizedVideoTimeline.play(personalizedVideo.currentTime - tlStart, true);
            }
        }
    });

    personalizedVideo.addEventListener('pause', function() {
        personalizedVideoTimeline.pause();
    });

    personalizedVideo.addEventListener('seeking', function() {
        personalizedVideoTimeline.pause();
        if (personalizedVideo.currentTime <= tlStart) {
            console.log('seek 0');
            personalizedVideoTimeline.seek(0, true);
        }
        else if (personalizedVideo.currentTime > tlStart && personalizedVideo.currentTime <= tlEnd) {
            personalizedVideoTimeline.seek(personalizedVideo.currentTime - tlStart, true);
            console.log('seek on timeline');
        }
        else if (personalizedVideo.currentTime > tlEnd) {
            personalizedVideoTimeline.seek('-=0' , true);
            console.log('seek end');
        }
    });

    $(window).on('resize', function() {
        setVideoDonutsSize();
    }).resize();

}

function setVideoDonutsSize() {
    var donut_chart_ratio = 0.3; // 30% of the video width
    var video_aspect_ratio = 1.7777;
    var video_w = $(personalizedVideo).width();
    if (video_w !== 0 ) { // video is not hidden
        let donut_size = (video_w * donut_chart_ratio) + 'px';
        $('.donut-chart-wrap--video').css({
            width: donut_size,
            height: donut_size,
            fontSize: donut_size,
        });
        $('#personalized-video-html').height(video_w/video_aspect_ratio);
    }
}

const app = new Vue({
    components: {
        Loader,
        DonutChart,
        VueSlider,
    },
    // router,
    data: {
        source: window.source || 'main',
        mobileMenuShow: false,
        displayShowFormulasZones: false,
        contentManagement: {
            showFormulas: false,
            nrEditorsDevs: 10,
            averageSalary: 90000,
            averageSalaryOptions: $.extend(true, {}, { 
                min: 12000,
                max: 200000,
                interval: 500,
                tooltipFormatter: TooltipFormatterNumberDollars,
            }, sliderDefaults),
            efficiencyGain: 0.5,
        },
        aiPersonalization: {
            showFormulas: false,
            averageOrder: 90,
            nrTransations: 500000,
            nrTransationsOptions: $.extend(true, {}, { 
                min: 1000,
                max: 1000000,
                interval: 100,
                tooltipFormatter: TooltipFormatterNumber,
            }, sliderDefaults),
            conversionRatePostEpi: 0.03,
            conversionRateEpiPers: 0.5,
            increasedBasketPerTrans: 0.055,
            riskAdjustment: 0.5,
        },
        azureCloud: {
            showFormulas: false,
            costOpServers: 35000,
            costOpServersOptions: $.extend(true, {}, { 
                min: 1000,
                max: 200000,
                interval: 100,
                tooltipFormatter: TooltipFormatterNumberDollars,
            }, sliderDefaults),
            upkeepServersCostsPercent: 20,
            daysDown: 2,
            daysDownOptions: $.extend(true, {}, { 
                min: 0.5,
                max: 14,
                interval: 0.5,
                tooltipFormatter: function(val) {
                    if (lang === 'sv') {
                        if (val == 1) return val + ' dag';
                        return val + ' dagar'; 
                    }
                    else {
                        if (val == 1) return val + ' day';
                        return val + ' days'; 
                    }
                },
            }, sliderDefaults),
        },
        activeChartCategoryIndex: 0,
        form: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            company: '',
            job_title: '',
            country: '',
            state: '',
        },
        allowFormSend: true,
        allowCalculatorAnimation: true,
        openedPopup: false,
        formButtonIsReady: false,
        isQuick: (window.location.search.indexOf('quick') !== -1),
    },

    computed: {

        savedContentManagementWithEpiserver() {
            return Math.round(this.contentManagement.nrEditorsDevs * this.contentManagement.averageSalary * (1 - this.contentManagement.efficiencyGain));
        },

        resultCalc_1() {
            return Math.round(this.aiPersonalization.nrTransations * this.aiPersonalization.conversionRatePostEpi);
        },
        resultCalc_2() {
            return Math.round(this.resultCalc_1 * this.aiPersonalization.conversionRateEpiPers);
        },
        resultCalc_3() {
            return Math.round(this.resultCalc_2 * this.aiPersonalization.averageOrder);
        },
        resultCalc_4() {
            return Math.round((this.aiPersonalization.nrTransations + this.resultCalc_1) * this.aiPersonalization.averageOrder * this.aiPersonalization.increasedBasketPerTrans);
        },
        resultCalc_5() {
            return Math.round(this.resultCalc_3 + this.resultCalc_4);
        },
        resultCalc_6() {
            return Math.round(this.resultCalc_5 * this.aiPersonalization.riskAdjustment);
        },
        savedAiPersonalizationWithEpiserver() {
            return this.resultCalc_6;
        },

        lossesOutagesScaling() {
            return Math.round(this.aiPersonalization.nrTransations * this.aiPersonalization.averageOrder / 365 * this.azureCloud.daysDown);
        },
        savedAzureCloudWithEpiserver() {
            return Math.round(this.azureCloud.costOpServers * (this.azureCloud.upkeepServersCostsPercent / 100) + this.lossesOutagesScaling);
        },

        totalSavedWithEpiserver() {
            return this.savedContentManagementWithEpiserver + this.savedAiPersonalizationWithEpiserver + this.savedAzureCloudWithEpiserver;
        },

        donutChartOptions() {
            return [
                this.savedContentManagementWithEpiserver,
                this.savedAiPersonalizationWithEpiserver,
                this.savedAzureCloudWithEpiserver,
            ];
        },

    },

    watch: {
        'contentManagement.nrEditorsDevs'() {
            this.activeChartCategoryIndex = 0;
        },
        'contentManagement.averageSalary'() {
            this.activeChartCategoryIndex = 0;
        },

        'aiPersonalization.averageOrder'() {
            this.activeChartCategoryIndex = 1;
        },
        'aiPersonalization.nrTransations'() {
            this.activeChartCategoryIndex = 1;
        },

        'azureCloud.costOpServers'() {
            this.activeChartCategoryIndex = 2;
        },
        'azureCloud.daysDown'() {
            this.activeChartCategoryIndex = 2;
        },

        'form.email'() {
            this.errorEmailFreeDomain = false;
        },

    },

    methods: {
        mouseoverCategoryBar(index) {
            this.activeChartCategoryIndex = index;
        },
        clickGetPersonalizedReport() {
            if (this.isQuick) {
                $('.kbs-btn--report')
                    .addClass('hidden')
                    .after('<div style="max-width: 312px; margin: 0 auto;">Your Personalized ROI Report download will begin soon. The process might take a few seconds.</div>');
                this.saveLead();
            }
            else {
                this.setFormTextPersonalized();
                $('body, html').animate({
                    scrollTop: $('#form-text-personalized').offset().top - 130
                }, 400);
            }
        },
        setFormTextPersonalized() {
            $('#form-text-get-started').addClass('d-none');
            $('#form-text-personalized').removeClass('d-none');
            $('#AdditionalInformation')
                .val('').attr('type', 'hidden')
                .parent('.c-form__form-item').css({height: 0, overflow: 'hidden'});
            if (!this.formButtonIsReady) {
                this.formButtonIsReady = true;
                this.customizeSubmitButton();
            }
        },
        customizeSubmitButton() {
            var $this = this;
            var btn = $('input[type="submit"].c-form__form-button');

            if ($this.source === 'b2c' || $this.source === 'forrester') {
                // btn.val('Get the Report');
            }
            else {
                if (lang === 'en') {
                    btn.val('Download Personalized Report');
                }
            }
            btn.on('click', function(e) {
                // if user filled in the form
                if (
                    $('#Name').val() !== '' &&
                    $('#Email').val() !== '' &&
                    $('#Company').val() !== '' &&
                    $('#EmploymentNumber').val() !== '' &&
                    $('#Role').val() !== '' &&
                    $('#Phone').val() !== ''
                ) {
                    e.preventDefault();
                    $this.saveLead();
                }
            });
        },
        setupSubmitButtonLoader() {
            var btnSubmit = $('input[type="submit"].c-form__form-button');
            btnSubmit
                .wrap('<div class="btn-submit-loader-wrap"></div>')
                .after('<div class="lds-css"><div class="lds-rolling"><div></div>');
        },
        clickSeePersonalizedVideo() {
            this.allowCalculatorAnimation = false;
            $.magnificPopup.open({
                items: {
                    src: '#popup-personalized-video'
                },
                callbacks: {
                    open: function() {
                        personalizedVideo.currentTime = 0;
                        personalizedVideo.play();
                        setVideoDonutsSize();
                    },
                    close: function() {
                        personalizedVideo.pause();
                        history.replaceState({}, '', window.location.href.replace(/\?.*/, ''));
                    },
                }
            });
            this.updateQueryParams();
        },
        updateQueryParams() {
            var query = '?' + $.param([
                { name: "a1", value: this.contentManagement.nrEditorsDevs },
                { name: "a2", value: this.contentManagement.averageSalary },
                { name: "b1", value: this.aiPersonalization.averageOrder },
                { name: "b2", value: this.aiPersonalization.nrTransations },
                { name: "c1", value: this.azureCloud.costOpServers },
                { name: "c2", value: this.azureCloud.daysDown },
                { name: "showVideo", value: 1 },
            ]);
            history.replaceState({}, '', query);
        },
        handleQueriedRequest() {
            if (window.location.search.indexOf('a1') !== -1) {
                let query = Helpers.URL_Params_to_Obj();
                this.allowCalculatorAnimation = false;
                this.contentManagement.nrEditorsDevs = parseInt(query.a1);
                this.contentManagement.averageSalary = parseInt(query.a2);
                this.aiPersonalization.averageOrder = parseInt(query.b1);
                this.aiPersonalization.nrTransations = parseInt(query.b2);
                this.azureCloud.costOpServers = parseInt(query.c1);
                this.azureCloud.daysDown = parseInt(query.c2);

                if (query.showVideo == 1) {
                    this.clickSeePersonalizedVideo();
                }
            }
        },
        saveDataToLocalStorage() {
            localStorage.setItem('a1', this.contentManagement.nrEditorsDevs);
            localStorage.setItem('a2', this.contentManagement.averageSalary);
            localStorage.setItem('b1', this.aiPersonalization.averageOrder);
            localStorage.setItem('b2', this.aiPersonalization.nrTransations);
            localStorage.setItem('c1', this.azureCloud.costOpServers);
            localStorage.setItem('c2', this.azureCloud.daysDown);
        },

        loadDataFromLocalStorage() {
            this.contentManagement.nrEditorsDevs = parseInt(localStorage.getItem('a1'));
            this.contentManagement.averageSalary = parseInt(localStorage.getItem('a2'));
            this.aiPersonalization.nrTransations = parseInt(localStorage.getItem('b1'));
            this.aiPersonalization.minutes = parseInt(localStorage.getItem('b2'));
            this.azureCloud.costOpServers = parseInt(localStorage.getItem('c1'));
            this.azureCloud.daysDown = parseInt(localStorage.getItem('c2'));
        },

        setupVertTabs() {
            var navs = $('.vert-nav');
            var tabs = $('.vert-tab');
            navs.click(function(e) {
                var nav = $(this);
                var id = nav.attr('data-vert-nav');
                var tab = tabs.filter('[data-vert-tab="' + id + '"]');
                navs.not(nav).removeClass('active');
                nav.addClass('active');
                tabs.not(tab).removeClass('active');
                tab.addClass('active');
            });

            $('a[href="#integration-tabs"]').on('click', function() { navs.eq(0).click(); });
        },

        setupMozaicClickHandlers() {
            var base = 'https://www.episerver.com/solutions/our-customers/';
            var urls = [
                base + 'featured-case-studies/eason-son/',
                base + 'by-industry/jenson-usa/',
                base + 'by-industry/premier-designs/',
                base + 'by-industry/polaris/',
            ];
            $(document).on('click', '.c-categories__link', function(e) {
                e.preventDefault();
                var i = $(e.currentTarget).parents('.c-categories__item').index();
                // window.location.href = urls[i];
                window.open(urls[i]);
            });
        },

        setupScrollMagic() {
            var $this = this;
            // https://scrollmagic.io/examples/basic/custom_actions.html
            var controller = new ScrollMagic.Controller();
            var scene = 
                new ScrollMagic.Scene({
                        triggerElement: '#roi-calculator',
                        triggerHook: 0.5,
                        duration: 200,
                        offset: 100,
                        reverse: false // only do once
                    })
                    .addTo(controller)
                    // .addIndicators() // for debugging
                    .on('enter', function (e) {
                        if ($this.allowCalculatorAnimation) {
                            console.log('scroll -> animateCalculator');
                            $this.animateCalculator();
                        }
                    });
        },

        animateCalculator() {
            var $this = this;
            var calcBtn = $('.kbs-btn--video');
            var calcBtnInterval;
            $this.animateCalculatorCardHeaders();
            setTimeout(() => {
                $this.animateCalculatorDonut();
            }, 1000);
            calcBtnInterval = setInterval(() => {
                calcBtn.removeClass('animating');
                setTimeout(() => {
                    calcBtn.addClass('animating');
                }, 100);
            }, 3500);
        },

        animateCalculatorCardHeaders() {
            var delayBetween = 600;
            var duration = 250;
            $('.category-card__header').each((i, el) => {
                var cardHeader = $(el);
                setTimeout(() => {
                    cardHeader.addClass('highlighted');
                    setTimeout(() => {
                        cardHeader.removeClass('highlighted');
                    }, duration);
                }, delayBetween * i);

                if (i == 2 && $('#slim-header').length) {
                    setTimeout(() => {
                        $('.category-card__header').eq(0).click();
                    }, delayBetween * i + duration + 3000);
                }
            });
        },

        animateCalculatorDonut() {
            var $this = this;
            setTimeout(() => {
                Helpers.animateNumber($this, 70, 1000);
                setTimeout(() => {
                    Helpers.animateNumber($this, 10, 1300);
                }, 1000);
            }, 300);
        },

        saveLead() {
            var $this = this;
            var formData = {
                full_name: $('#Name').val(),
                email: $('#Email').val(),
                company: $('#Company').val(),
                employees: $('#EmploymentNumber').val(),
                role: $('#Role').val(),
                country: $('#Country').val(),
                phone: $('#Phone').val(),
                info: $('#AdditionalInformation').val(),
                a1: $this.contentManagement.nrEditorsDevs,
                a2: $this.contentManagement.averageSalary,
                b1: $this.aiPersonalization.averageOrder,
                b2: $this.aiPersonalization.nrTransations,
                c1: $this.azureCloud.costOpServers,
                c2: $this.azureCloud.daysDown,
                saved_a: $this.savedContentManagementWithEpiserver,
                saved_b: $this.savedAiPersonalizationWithEpiserver,
                saved_c: $this.savedAzureCloudWithEpiserver,
                saved_total: $this.totalSavedWithEpiserver,
                source: $this.source,
            };

            $this.allowFormSend = false;
            $.ajax({
                url: 'https://episerver-cms.kubis.ro/api/ms-dynamics/save',
                method: 'post',
                dataType: 'json',
                data: formData,
                success(data, textStatus, request) {
                    // console.log(request);
                    if (data.error == 1) {
                        console.log(data.message);
                        return false;
                    }
                    else if (data.success == 1 && data.MSDynamicsReportID) {
                        localStorage.setItem('MSDynamicsReportID', data.MSDynamicsReportID);
                        var inputTYpage = $('#ThankYouPage');

                        if ($this.source === 'main') {
                            inputTYpage.val(inputTYpage.val() + '?MSDynamicsReportID=pdf-' + data.MSDynamicsReportID);
                            $('#AdditionalInformation').val('pdf-' + data.MSDynamicsReportID);
                        }
                        else if ($this.source === 'b2c' || $this.source === 'forrester') {
                            // inputTYpage.val('https://www.episerver.com/success/think/thank-you-for-downloading/ty-tei-bundle/');
                        }

                        if ($this.isQuick) {
                            window.location.href = 'https://episerver-cms.kubis.ro/pdf-download/' + data.MSDynamicsReportID;
                        }
                        else {
                            $('.c-form__form').submit();
                        }
                    }
                    // $this.saveDataToLocalStorage();
                },
                complete(req) {
                    // console.log(req);
                    $this.allowFormSend = true;
                }
            });
        },

        setupHeroGrid() {
            var btnHeroGridCta = $('#hero-grid .js-click-calculate');
            var heroInterval;
            $(document).on('click', '.js-click-calculate', function(e) {
                e.preventDefault();
                $('body, html').animate({
                    scrollTop: $('#roi-calculator').offset().top - 65
                }, 400);
                clearInterval(heroInterval);
            });
            heroInterval = setInterval(() => {
                btnHeroGridCta.removeClass('animating');
                setTimeout(() => {
                    btnHeroGridCta.addClass('animating');
                }, 100);
            }, 2000);
            $(document).on('keyup', function(e) { 
                var isShift = !!e.shiftKey;
                if (isShift && e.which == '65') { // Left Shift + A
                    $('#hero-grid').toggleClass('hover-animations');
                }
            });
        },

        setupWistiaEmbedPersonalizedVideo() {
            var $this = this;
            // https://wistia.com/support/developers/player-api#get-started
            window._wq = window._wq || [];
            _wq.push({
                id: 'n3bmxbzaq6',
                 onReady: function(wistiaVideo) {
                    var wistiaEmbedWrap = $('#wistia-embed-personalized-video');
                    personalizedVideo = wistiaEmbedWrap.find('video').get(0);
                    console.log("I got a handle to the video!", wistiaVideo);
                    // console.log(personalizedVideo);
                    $(personalizedVideo).after($('#personalized-video-html'));
                    wistiaEmbedWrap.appendTo('#kbs-popup-video');
                    setupPersonalizedVideoAnimations();
                    wistiaVideo.bind("heightchange", function() {
                        console.log("The height changed to " + wistiaVideo.height());
                        console.log("video aspect " + wistiaVideo.aspect());
                        setVideoDonutsSize();
                    });
                    $('#kbs-btn--video').removeClass('hidden');
                    $this.handleQueriedRequest();
                }
            });
        },

        setupVideoShare() {
            $('#btn-video-share').on('click', function() {
                var clipboard = $('#clipboard-helper');
                clipboard.val(window.location.href);
                clipboard.select();
                document.execCommand("copy");
                $('#btn-video-share').tooltip_bs4('show');
                setTimeout(() => {
                    $('#btn-video-share').tooltip_bs4('hide');
                }, 1000);
            })
        },

        setupCardHeaders() {
            var $this = this;
            $('.category-card__header').click(function(e) {
                var categoryCard = $(e.currentTarget).parents('.category-card');
                var openContentHeight;
                
                $('.category-card').not(categoryCard).removeClass('open');
                categoryCard.toggleClass('open');
                
                app.$children
                    .filter(child => child.$options.name === 'VueSliderComponent')
                    .forEach(slider => slider.refresh());
                
                if (categoryCard.hasClass('open')) {
                    $this.activeChartCategoryIndex = parseInt(categoryCard.attr('data-chart-index'));
                }
                
                openContentHeight = categoryCard.find('.open-content__inner').outerHeight();
                $('#roi-calculator-spacer').height(openContentHeight);
            });
        }
    },

    mounted () {
        var $this = this;

        this.$nextTick(() => {

            var $this = this;

            if (window.location.search.indexOf('showVideo=1') !== -1) {
                $this.allowCalculatorAnimation = false;
            }
            if (window.location.href.indexOf('/dynamics-roicalculator') !== -1) {
                $this.source = 'slim';
            }

            if ($this.isQuick) {
                $this.source = 'quick';
                $('#form-text-personalized').parents('.block.twocolumntextblock').eq(0).hide();
            }

            $this.setupHeroGrid();

            $this.setupCardHeaders();

            $this.setupVertTabs();

            $this.setupMozaicClickHandlers();

            $this.setFormTextPersonalized();

            setTimeout(() => {
                $this.setupScrollMagic();
            }, 1000);

            if ($.fn.tooltip_bs4) {
                $('[data-toggle="bs4.tooltip"]').tooltip_bs4();
            }

            $this.setupSubmitButtonLoader();

            $this.setupWistiaEmbedPersonalizedVideo();

            $this.setupVideoShare();
        });

    },
}).$mount('#app-roi-calculator');

});