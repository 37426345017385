<template>
    <!-- https://loading.io/# -->
    <svg class="loader" :width="size" :height="size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
        <g transform="translate(50,50)">
            <g transform="scale(0.7)">
                <g transform="translate(-50,-50)">
                    <g transform="rotate(240 50 50)">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.625s" begin="0s" repeatCount="indefinite"></animateTransform>
                        <path d="M50 50L50 0A50 50 0 0 1 100 50Z" fill-opacity="0.8" :fill="color_1"></path>
                    </g>
                    <g transform="rotate(9.63917e-8)">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.8333333333333334s" begin="0s" repeatCount="indefinite"></animateTransform>
                        <path d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)" fill-opacity="0.8" :fill="color_2"></path>
                    </g>
                    <g transform="rotate(120 50 50)">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.25s" begin="0s" repeatCount="indefinite"></animateTransform>
                        <path d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)" fill-opacity="0.8" :fill="color_3"></path>
                    </g>
                    <g transform="rotate(240 50 50)">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="2.5s" begin="0s" repeatCount="indefinite"></animateTransform>
                        <path d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)" fill-opacity="0.8" :fill="color_4"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Loader',
    props: [
        'size',
        'color_1',
        'color_2',
        'color_3',
        'color_4',
    ],
}
</script>