<template>
    <svg height="160" width="160" viewBox="0 0 160 160" class="donut-chart">
        <g v-for="(value, index) in sortedValues" v-if="chartData.length">
            <circle class="donut-part" @mouseover="mouseoverPart(index)" :class="{ active: activeIndex == index }" :cx="cx" :cy="cy" :r="radius" :stroke="colors[index]" :stroke-width="strokeWidth" :stroke-dasharray="adjustedCircumference" :stroke-dashoffset="calculateStrokeDashOffset(value, circumference)" fill="transparent" :transform="returnCircleTransformValue(index)" />
        </g>
        <g>
            <circle :cx="cx" :cy="cy" :r="radius" fill="#ffffff"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'DonutChart',
    props: [
        'initialValues', 'activeIndex', 'formatInactive', 'inactiveColorIndex'
    ],
    data() {
        return {
            angleOffset: -90,
            chartData: [],
            cx: 80,
            cy: 80,           
            radius: 60,
            strokeWidth: 20,
            gap: 0,
        }
    },
    watch: {
        initialValues(newValues) {
            this.angleOffset = -90;
            this.drawChart();
        },
    },
    computed: {
        colors() {
            var colors = ['#699bff', '#ff553e', '#05149b'];
            if (this.formatInactive) {
                colors = [colors[parseInt(this.inactiveColorIndex)], '#ccc'];
            }
            return colors;
        },
        // adjust the circumference to add small white gaps
        adjustedCircumference() {
            return this.circumference - this.gap;
        },
        circumference() {
            return 2 * Math.PI * this.radius;
        },
        dataTotal() {
            return this.sortedValues.reduce((acc, val) => acc + val);
        },
        calculateChartData() {
            this.chartData = [];
            this.sortedValues.forEach((dataVal, index) => {
                // start at -90deg so that the largest segment is perpendicular to top
                const data = {
                    degrees: this.angleOffset,
                };
                this.chartData.push(data);
                this.angleOffset = this.dataPercentage(dataVal) * 360 + this.angleOffset;       
            });
        },
        sortedValues() {
            return this.initialValues;
        },
    },
    methods: {
        calculateStrokeDashOffset(dataVal, circumference) {
            const strokeDiff = this.dataPercentage(dataVal) * circumference;   
            return circumference - strokeDiff;
        },
        degreesToRadians(angle) {
            return angle * (Math.PI / 180);
        },
        dataPercentage(dataVal) {
            if (isNaN(dataVal) || this.dataTotal === 0) return 1;
            return dataVal / this.dataTotal;
        },
        percentageString(dataVal) {
            return `${Math.round(this.dataPercentage(dataVal) * 100)}%`;
        },
        returnCircleTransformValue(index) {
            return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`;
        },
        segmentBigEnough(dataVal) {
            return Math.round(this.dataPercentage(dataVal) * 100) > 5;
        },
        mouseoverPart(index) {
            this.$root.activeChartCategoryIndex = index;
        },
        drawChart() {
            this.calculateChartData;
        },
    },
    mounted() {
        this.drawChart();
    }
}
</script>